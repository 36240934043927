import React, { Component } from "react";
import "../styles/mainPage-styles.css";
import InfoWidget from "./infoWidget";
import Lottie from "lottie-react";
import animationData from "../assets/software-animation.json";

class WelcomeMessage extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="intro-container" id="fade">
          <h1 className="main-header flex-child">Hello, I'm Erik. </h1>
          <p className="main-body flex">
            Welcome to my portfolio, please have a look around.
          </p>
        </div>
        <div className="animation-container main-header flex-child">
          <Lottie animationData={animationData} />
        </div>
      </div>
    );
  }
}

export default WelcomeMessage;
