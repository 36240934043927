import { Component, useState, useEffect } from "react";
import "../styles/projectsStyles.css";
import dataFile from "../data/projects";
import ProjectCard from "../components/projectCard";
import Lottie from "lottie-react";
import underConstructionAnimation from "../assets/under-construction.json";

class ProjectsPage extends Component {
  state = {
    selectedOption: "All",
  };

  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  render() {
    const { selectedOption } = this.state;
    const filteredLanguage = dataFile
      .map(({ code }) => code && code[0].language)
      .filter((x, i, a) => a.indexOf(x) === i);

    return (
      <div className="page">
        <div className="project-section">
          <div className="filter">
            <select id="searchFilter" onChange={this.handleChange}>
              <option value="All">All</option>
              {filteredLanguage.map((language) => (
                <option value={language}>{language}</option>
              ))}
            </select>
          </div>
          {dataFile.map(({ title, body, links, index, code }) =>
            code && code[0].language === selectedOption ? (
              <ProjectCard
                key={index}
                title={title}
                body={body}
                links={links}
                code={code}
              />
            ) : selectedOption === "All" ? (
              <ProjectCard
                key={index}
                title={title}
                body={body}
                links={links}
                code={code}
              />
            ) : null
          )}
          {/* <div className="animation">
            <Lottie animationData={underConstructionAnimation} />
          </div> */}
        </div>
      </div>
    );
  }
}

export default ProjectsPage;
