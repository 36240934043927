import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Lottie from "lottie-react";
import underConstructionAnimation from "../assets/under-construction.json";
import blogAnimation from "../assets/blog.json";
import calculatorAnimation from "../assets/calculator.json";
import "../styles/projectsStyles.css";

class personalProjects extends Component {
  render() {
    return (
      <div className="project-section">
        <h1 className="under-construction-heading">Personal Projects</h1>
        <div className="card-container">
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie animationData={blogAnimation} />
                  <Card.Title>Sample Personal Blog</Card.Title>
                  <Card.Text>
                    A simple personal blog website using Node.js, Express, and
                    MongoDB to perform CRUD actions on various blog postings.
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://sample-blog-app.onrender.com"
                    variant="dark"
                    target="_blank"
                  >
                    View Live Demo
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie animationData={calculatorAnimation} />
                  <Card.Title>VEE Usage and Rebill Calculator</Card.Title>
                  <Card.Text>
                    A web app created to calculate a customer's average energy
                    consumption and determine a correct estimated amount when
                    reads are unavailable. This is mainly used when electrical
                    meters fail and estimated usage needs to be fairly
                    calculated utilizing various factors. The web app also
                    generates a .docx file letter that can be mailed to the
                    customer explaining the cause of the rebill and the new
                    amounts billed.
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://vee.erikengvall.com/"
                    variant="dark"
                    target="_blank"
                  >
                    View Live Demo
                  </Button>
                  <Button
                    href="https://github.com/EEngvall/VEE-Calculator"
                    variant="dark"
                    className="mt-2"
                  >
                    View Github Repo
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default personalProjects;
