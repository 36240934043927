import React from 'react';
import "../styles/experienceStyles.css"


const CertificationCard = ({ title, issuingOrganization, issuedDate }) => (
  <div className="card experience-card certification-card">
    <h3 className="card-title">{title}</h3>
    <p className="card-subtitle">{issuingOrganization}</p>
    <p className="card-text">{issuedDate}</p>
  </div>
);

export default CertificationCard;
