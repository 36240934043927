import React, { Component } from "react";
import Splash from "../img/splash-background.jpg";
import Profile from "../img/profile.jpg";
import "../styles/InfoWidgetStyles.css";
import CurrentMusic from "./currentMusic";
import HobbiesWidget from "./hobbiesWidget";
import HistoryWidget from "./historyWidget";
import CurrentRoleCard from "./currentRoleCard";
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>;

class InfoWidget extends Component {
  state = {};
  render() {
    return (
      <div>
        <div>
          <HistoryWidget />
        </div>
        <div>
          <CurrentRoleCard />
        </div>
        <div>
          {/* <CurrentMusic /> */}
          <HobbiesWidget />
        </div>
      </div>
    );
  }
}

export default InfoWidget;
<h1>Info Widget</h1>;
