import React, { Component } from "react";
import ContactMeInput from "../components/contactMeInput";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "../styles/contactMeStyles.css";

class ContactMe extends Component {
  state = {};
  render() {
    return (
      <>
        <div>
          <ContactMeInput />
        </div>
      </>
    );
  }
}

export default ContactMe;
