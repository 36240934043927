import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavigationPanel from "./components/navigationPanel";
import ContactMe from "./routes/contactMe";
import MainPage from "./routes/mainPage";
import Projects from "./routes/projects";
import ExperiencePage from "./routes/experience";
import PersonalProjects from "./routes/personalProjects";
import SchoolProjects from "./routes/schoolProjects";
import AboutMeMain from "./routes/aboutMeMain";
import "./styles/index.css";
const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <NavigationPanel />
    <Routes>
      <Route path="contactMe" element={<ContactMe />} />
      <Route path="/home" element={<MainPage />} />
      <Route path="aboutMeMain" element={<AboutMeMain />} />
      <Route path="experience" element={<ExperiencePage />} />
      <Route path="projects" element={<Projects />} />
      <Route path="schoolProjects" element={<SchoolProjects />} />
      <Route path="personalProjects" element={<PersonalProjects />} />
      <Route path="/" element={<Navigate replace to="/home" />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);
