import React, { Component } from "react";
import "../styles/InfoWidgetStyles.css";

class currentRoleCard extends Component {
  render() {
    return (
      <div className="gradient-border infowidget-container">
        <div className="infowidget-body">
          <h1>Goals and Accomplishments</h1>
          <p className="infowidget-body">
            With a Bachelor of Science degree in Computer Science from SNHU and
            a passion for software development, I am excited to leverage my
            skills and experience to contribute to the success of a future team.
            One of my notable experiences in my current role includes my
            experience in testing and implementing the new customer service
            website, known internally as DSS (Digital Self Service), during my
            tenure at Turlock Irrigation District. In this role, I played a
            pivotal part in the successful launch of the website by creating
            comprehensive test cases, conducting testing for customer-facing
            functionality, and meticulously logging bugs or errors for
            resolution. Additionally, I actively participated in weekly standup
            meetings to report progress and communicate any blockers or
            obstacles.
            <br></br>
            <br></br>
            My involvement extended beyond testing, as I also contributed to
            user acceptance testing and provided training to current customer
            service representatives on navigating and utilizing the DSS
            platform. This encompassed training them on the TID-facing aspects
            of the website, ensuring smooth adoption and proficient usage.
            Moreover, I collaborated directly with Oracle to address encountered
            issues and implement additional features, showcasing my ability to
            communicate effectively with external partners and drive successful
            project outcomes. In addition to my technical skills, I possess a
            strong aptitude for general clerical duties and customer service. I
            have performed a variety of tasks, such as providing information on
            departmental policies and procedures, processing service requests,
            updating customer account records, and addressing inquiries and
            complaints across a wide range of District topics. These experiences
            have honed my ability to deliver exceptional customer service,
            handle diverse situations, and maintain a meticulous attention to
            detail. With my solid foundation in software development and my
            customer-centric approach, I am confident in my ability to thrive in
            any dynamic and innovative environment. I am eager to contribute my
            skills, creativity, and dedication to drive the development of
            impactful software solutions.
          </p>
        </div>
      </div>
    );
  }
}

export default currentRoleCard;
