import React, { Component } from "react";
import "../styles/InfoWidgetStyles.css";

class HistoryWidget extends Component {
  state = {};
  render() {
    return (
      <div className="gradient-border infowidget-container">
        <div className="infowidget-body">
          <h1>A Brief History</h1>
          <p className="infowidget-body">
            I'm a recent graduate from Southern New Hampshire University. I
            recieved my Bachelor of Science in Computer Science. I currently
            work for Turlock Irrigation DIstrict as a Customer Service
            Representative. Prior to my current position I worked for Gold
            Gaming Consultants as a Division Manager for their Third Party
            Proposition Player services. I spent 10 years working in the
            Gambling and Gaming industry helping to provide game security and
            prop player services across 6 different card rooms and casinos.
          </p>
        </div>
      </div>
    );
  }
}

export default HistoryWidget;
