import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/mainPage-styles.css";
import InfoWidget from "../components/infoWidget";

class AboutMeMain extends Component {
  state = {};
  render() {
    return <InfoWidget />;
  }
}

export default AboutMeMain;
