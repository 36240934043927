import React from "react";
import { Component } from "react";
import UnderConstruction from "../components/underConstruction";
import { Card, Button } from "react-bootstrap";
import Lottie from "lottie-react";
import reviewAnimation from "../assets/review.json";
import videoAnimation from "../assets/video.json";
import pandaAnimation from "../assets/panda.json";
import dogAnimation from "../assets/dog.json";
import "../styles/projectsStyles.css";

class schoolProjects extends Component {
  render() {
    return (
      <div className="project-section">
        <h1 className="under-construction-heading">School Projects</h1>
        <div className="card-container">
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie animationData={reviewAnimation} />
                  <Card.Title>Professional Self Assessment</Card.Title>
                  <Card.Text>
                    A self assessment done on the entirety of my coding journey
                    through school. In this I discuss my own progression as well
                    as what decisions I would make now versus when the code was
                    originally written.
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://github.com/EEngvall/eengvall.github.io/blob/main/index.md"
                    variant="dark"
                  >
                    View Self Assessment
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie speed={0.05} animationData={videoAnimation} />
                  <Card.Title>Video Code Review</Card.Title>
                  <Card.Text>
                    This code review shows that I have the ability to use
                    interaction to create code-reviews, as well as the ability
                    to understand code reviews both individually and within a
                    team environment. The code review also demonstrates my
                    ability to provide contextual in-code comments that are
                    eaisly readable and understandable
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://eengvall.github.io/videos/CS-499.mov"
                    variant="dark"
                  >
                    Download Video Code Review
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie animationData={pandaAnimation} />
                  <Card.Title>
                    Artifact 1: Software Design and Engineering
                  </Card.Title>
                  <Card.Text>
                    This artifact is a zoo authentication system from IT-145.
                    This artifact focuses on verifying user input against
                    provided credentials and displaying a given text file based
                    on the role the user is associated with. This artifact was
                    originally created during one of the first terms I
                    participated in at SNHU and I feel it demonstrates some of
                    the progress I’ve made as a developer throughout the
                    program.
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://github.com/EEngvall/Python-Auth-System"
                    variant="dark"
                  >
                    View Artifact
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie animationData={pandaAnimation} />
                  <Card.Title>
                    Artifact 2: Algorithms and Data Structures
                  </Card.Title>
                  <Card.Text>
                    This artifact is the same zoo authentication system from
                    IT-145 and the previous project. The distinction between
                    this artifact submission and the one from Milestone 2 is
                    that this artifact focuses on the data structures used, in
                    this case the inclusion of a JSON file.
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://github.com/EEngvall/Python-Auth-System"
                    variant="dark"
                  >
                    View Artifact
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="card-individual">
            <Card style={{ width: "20rem", height: "100%" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Lottie animationData={dogAnimation} />
                  <Card.Title>Artifact 3: Databases</Card.Title>
                  <Card.Text>
                    This artifact is the database used in CS-340 for the AAC
                    Animal shelter. This artifact is a database used to query
                    animal records for animals processed by the animal shelter.
                    I have included both the files for the project, as well as a
                    link to the live web app. The current iteration of the
                    project only allows for reading from the database. The goal
                    is to have the final iteration capable of both adding and
                    removing entries into the database.
                  </Card.Text>
                </div>
                <div className="card-button">
                  <Button
                    href="https://github.com/EEngvall/CS-340-Final-Project"
                    variant="dark"
                  >
                    View Artifact
                  </Button>
                </div>
                <div>
                  <Button
                    href="https://cs-live-rescue-app.onrender.com"
                    variant="dark"
                  >
                    View Live Demo
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default schoolProjects;
