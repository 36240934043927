import React, { Component, useState } from "react";
import "../styles/contactMeStyles.css";
import Axios from "axios";

function ContactMeInput() {
  return (
    <div className="contact-me-main">
      <div>
        {" "}
        <h1>Click the button below to send me a direct message.</h1>
      </div>
      <div>
        {" "}
        <button
          onClick={() => (window.location = "mailto:Erik@ErikEngvall.com")}
        >
          Contact Me
        </button>
      </div>
    </div>
  );
}

export default ContactMeInput;
