import React from "react";
import "../styles/experienceStyles.css";
import "bootstrap/dist/css/bootstrap.min.css";

const EducationCard = ({ institution, degree, dateRange, honors }) => {
  const uniqueInstitution = institution.replace(/\s+/g, "-").toLowerCase();

  return (
    <div className="card experience-card">
      <button
        class="btn btn-light"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${uniqueInstitution}`}
        aria-expanded="false"
        aria-controls="collapseCard"
      >
        {institution}
      </button>
      <div className="collapse show" id={uniqueInstitution}>
        <div className="card-body">
          <h3 className="card-title">{institution}</h3>
          <p className="card-subtitle">{dateRange}</p>
          <h4 className="card-text">{degree}</h4>
          <p className="card-subtitle">{honors}</p>
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
