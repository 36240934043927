import React, { Component } from "react";
import "../styles/mainPage-styles.css";
import ExperienceCard from "../components/experienceCard";
import CertificationCard from "../components/certificationCard";
import EducationCard from "../components/educationCard";

class ExperiencePage extends Component {
  state = {};
  render() {
    return (
      <div className="page">
        <div className="experience-section">
          <h1 className="section-title">Work Experience</h1>
          <ExperienceCard
            company="Turlock Irrigation District"
            dateRange="January 2020 - Present"
            title="Customer Service Rep I"
            responsibilities={[
              "Helped to test and implement our new customer service website known internally as DSS. Including but not limited to: Creating test cases for (and testing) customer facing functionality and keeping logs of all bugs or errors, participating in weekly standup meetings regarding project progress and reporting and detailing any blockers or obstacles, assisting with user acceptance testing as well as training current customer service representatives on how to navigate and work within DSS on the TID facing aspects, worked directly with Oracle to resolve issues that were encountered as well as implement additional features. ",
              "Collaborated with cross-functional teams to solve technical challenges related to the implemenatation of the above project",
              "Perform a variety of general clerical duties.  Provide general information on departmental and District policies and procedures as required and refer to appropriate department personnel.",
              "Prepare and process requests for services such as:  new account requests, discontinuance of accounts, transfers, and requests for irrigation water.  Remotely read, seal and unseal meters and update customer account record.  Identify applicable rate schedule or water charges, unique billing scenarios, deposit requirements, inspections, rental agreement, fees, outstanding balances, etc. and convey necessary information to customers.  Inform irrigation customers of seasonal changes.",
              "Assist customers over the phone, in person and in writing with inquiries and complaints across a wide variety of District topics that could include, but not limited to: rate schedules, charges, account balances and histories, extensions, online services, payments, usage, refunds, programs, rebates and high bill inquiries.",
            ]}
          />
          <ExperienceCard
            company="Gold Gaming Consultants"
            dateRange="February 2012 - January 2020"
            title="Team Lead"
            responsibilities={[
              "Responsible for managing the overall daily operation of Gold Gaming Consultants including direct supervision of leads and players as well as support management and perform management duties when the General Manager is otherwise unavailable.",
              "Enforced all policies and procedures set forth in the Gold Gaming Consultants handbook.",
              "Assisted with human resources functions including performance evaluations and progressive discipline.",
              "Generated and shared comprehensive and detailed reports about team performance and any behavioral or procedural discrepancies to company owner. ",
              "Managed day-to-day operations of table games including counseling of shift leads and team members, interactions with other departments, managing inventories and stock, and advising on ordering equipment and supplies when needed. ",
              "Generated and oversaw scheduling, as well as worked directly with Casino supervisors and management for effective coverage of gaming activity. ",
              "Provided encouragement to team members, acted as motivator and mentor, listened to team member’s feedback and resolved and issues or conflicts including communicating team goals and identifying areas for new training and/or skill checks. ",
            ]}
          />
          <ExperienceCard
            company="AutoZone Inc."
            dateRange="March 2008 - February 2012"
            title="Customer Service Rep"
            responsibilities={[
              "Ensured assigned store tasks are completed in a timely manner on assigned shift",
              "Operates cash registers and follows established cash handling procedures",
              "Ensures that merchandise is restocked and placed in their respective areas",
              "Communicated with managers regarding customer concerns and employee matters",
              "Actively engaged in developing more effective customer service skills",
              "Provided honest and trustworthy advice to customers regarding the best products that fit the customers’ expectations",
            ]}
          />
        </div>
        <div className="certification-section">
          <h1 className="section-title">Certifications</h1>
          <CertificationCard
            title="AWS Cloud Practitioner"
            issuingOrganization="Amazon Web Services"
            issuedDate="April 15th, 2022"
          />
          <h1 className="section-title">Education</h1>

          <EducationCard
            institution="Southern New Hampshire University"
            degree="B.S. Computer Science"
            dateRange={"2018-2022"}
            honors={"Magna Cum Laude"}
          />
        </div>
      </div>
    );
  }
}

export default ExperiencePage;
