import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import Spotify from "react-spotify-embed";
import Lottie from "lottie-react";
import musicAnimation from "../assets/music-visualizer.json";

class CurrentMusic extends Component {
  state = {};
  render() {
    return (
      <div className="infowidget-border infowidget-container">
        <div className="infowidget-body">
          <div>
            <h1>Currently Listening To...</h1>
          </div>
          <div>
            <Lottie animationData={musicAnimation} />
          </div>
        </div>
        <div className="media-player">
          <Spotify link="https://open.spotify.com/album/2Oyi0iQ92nw0MlO6ehpNwJ?si=d89mG02-Rum2OJiZbA7Yeg" />
        </div>
      </div>
    );
  }
}

export default CurrentMusic;
