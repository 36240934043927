import React from "react";
import "../styles/experienceStyles.css";
import "bootstrap/dist/css/bootstrap.min.css";

function ExperienceCard({ company, dateRange, title, responsibilities }) {
  const uniqueCompany = company.replace(/\s+/g, "-").toLowerCase();

  return (
    <div className="card experience-card experience-btn">
      <button
        class="btn btn-light"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${uniqueCompany}`}
        aria-expanded="false"
        aria-controls="collapseCard"
      >
        {company}
      </button>
      <div
        className={
          company === "Turlock Irrigation District"
            ? "collapse show"
            : "collapse"
        }
        id={uniqueCompany}
      >
        <div className="card-body">
          <h3 className="card-title">{company}</h3>
          <p className="card-subtitle mb-2 text-muted">{dateRange}</p>
          <h4 className="card-text">{title}</h4>
          <ul className="list-group list-group-flush">
            {responsibilities.map((responsibility) => (
              <li key={responsibility} className="list-group-item">
                {responsibility}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ExperienceCard;
