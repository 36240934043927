import React, { Component } from "react";
import "../styles/InfoWidgetStyles.css";

class HobbiesWidget extends Component {
  state = {};
  render() {
    return (
      <div className="gradient-border infowidget-container">
        <div className="infowidget-body">
          <h1>My Hobbies</h1>
          <p>
            I enjoy many hobbies, most of which involve some form of creativity
            and problem solving. I Build and tinker with a number of things and
            enjoy many DIY projects. I have spent quite a while creating a
            versatile workshop in my garage that allows for woodworking and
            metal working for many projects, as well as the ability to repair
            and modify my vehicles. I use many of these skills to help my fiancé
            run her business, ModRoots Design, where we design and build hand
            crafted items ranging from simple charcuterie boards to reception
            desks using all manner of materials such as wood and epoxy,
            sometimes incorporating things such as metal and fiberglass. When
            I’m not in my garage I like to work with small electronics such as
            the raspberry pi or Arduino to automate things or add previously
            unavailable functionality. I have also within the last few years
            gotten into 3D printing with both FDM and Resin printers taking up
            residence in my office. I will be getting married in April of 2023
            and my future wife and I will be visiting Greece for three weeks for
            our honeymoon and we plan to enjoy all there is to see and do there
            as well.{" "}
          </p>
        </div>
      </div>
    );
  }
}

export default HobbiesWidget;
