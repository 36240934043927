import React, { Component, useEffect } from "react";
import "../styles/projectsStyles.css";
import "bootstrap/dist/css/bootstrap.min.css";

function ProjectCard({ title, body, links, code }) {
  const uniqueTitle = title.replace(/\s+/g, "-").toLowerCase();
  const languageColors = {
    "No Code": "text-bg-secondary",
    Python: "text-bg-warning",
    Javascript: "btn-success",
    Java: "text-bg-primary",
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <button
          class="project-btn btn btn-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${uniqueTitle}`}
          aria-expanded="false"
          aria-controls="collapseCard"
        >
          <span
            class={`code-language badge rounded-pill ${
              languageColors.hasOwnProperty(code.length && code[0].language)
                ? languageColors[code[0].language]
                : "text-bg-dark"
            }`}
          >
            {code.length && code[0].language}
          </span>
          {title}
        </button>
      </div>
      <div className="collapse" id={uniqueTitle}>
        <div className="card project-card">
          <div className="card-body">
            <h3 className="card-title text-center">{title}</h3>
            <br />
            <ul className="list-group list-group-flush">
              {body.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </ul>
            {code && (
              <ul>
                {code.map((code, index) => (
                  <div className="overflow-auto project-code">
                    <pre>
                      <code key={index}>{code.snippet}</code>
                    </pre>
                  </div>
                ))}
              </ul>
            )}
            {links.map((link) => (
              <div key={link.url}>
                <a href={link.url}>{link.displayText}</a>
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
