import { React, Component } from "react";
import Lottie from "lottie-react";
import underConstructionAnimation from "../assets/under-construction.json";

class UnderConstruction extends Component {
  state = {};
  render() {
    return (
      <div className="animation">
        <Lottie animationData={underConstructionAnimation} />
      </div>
    );
  }
}

export default UnderConstruction;
