import React, { Component } from "react";
import ProfilePicture from "../img/profile.jpg";
import "../styles/portrait.css";

class ProfilePortrait extends Component {
  state = {};
  render() {
    return (
      <div className="fade-in-image">
        <img className="profile-picture" src={ProfilePicture} />;
      </div>
    );
  }
}

export default ProfilePortrait;
