import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/mainPage-styles.css";
import ProfilePicture from "../components/profilePicture";
import WelcomeMessage from "../components/welcomeMessage";

class MainPage extends Component {
  state = {};
  render() {
    return (
      <div className="main-container">
        <div className="flex-child">
          <WelcomeMessage />
        </div>
        <div className="flex-child profile-picture-container">
          <ProfilePicture />
        </div>
      </div>
    );
  }
}

export default MainPage;
