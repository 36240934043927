import React, { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import favicon from "../img/favicon-32x32.png";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../styles/navbar-styles.css";

class NavigationPanel extends Component {
  state = {};
  componentDidMount() {
    document.title = "Erik's Portfolio";
  }
  render() {
    return (
      <Navbar
        className="bg-body-tertiary"
        collapseOnSelect
        bg="dark"
        data-bs-theme="dark"
        expand="lg"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/home">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/aboutMeMain">
                About Me
              </Nav.Link>
              <NavDropdown title="Projects" id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to="/schoolProjects">
                  School Projects
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="personalProjects">
                  Personal Projects
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://github.com/EEngvall"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavigationPanel;
